<template>
  <div class="scanCenter">
    <div>
      <h1>操作提示</h1>
      <div style="line-height: 100px">
        <i style="font-size: 100px;color: #a10623;" class="el-icon-error"></i>
      </div>
      <h3>{{msg}}</h3>
      <div style="padding: 20px 20px 0 20px;">
        <el-button style="width: 100%" type="primary">我已了解</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "error",
  data(){
    return{
      msg: '很遗憾，发生错误了。如有疑问，请联系我司客服热线。'
    }
  },
  mounted() {
    const me = this;
    const msg = me.$route.query.msg;
    if(msg){
      me.msg = msg;
    }
  }
}
</script>

<style scoped>
.scanCenter{
  text-align: center;
  padding: 25px;
}
</style>